<div class="position-sticky d-sm-block">
  <a
    [routerLink]="['/']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    class="d-flex justify-content-center align-items-center"
  >
    <i-feather name="home" class="small-icon"></i-feather>
  </a>
  <a
    [routerLink]="['/about']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    class="d-flex justify-content-center align-items-center"
  >
    <i-feather name="user" class="small-icon"></i-feather>
  </a>
  <a
    [routerLink]="['/resume']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    class="d-flex justify-content-center align-items-center"
  >
    <i-feather name="file-text" class="small-icon"></i-feather>
  </a>
  <a
    [routerLink]="['/portfolio']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: false }"
    class="d-flex justify-content-center align-items-center"
  >
    <i-feather name="folder" class="small-icon"></i-feather>
  </a>
  <a
    [routerLink]="['/contact']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    class="d-flex justify-content-center align-items-center"
  >
    <i-feather name="mail" class="small-icon"></i-feather>
  </a>
</div>
