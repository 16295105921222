import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { NgbConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'jc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'd-block'
  }
})
export class AppComponent implements OnInit {
  constructor(private ngbConfig: NgbConfig) {
    this.ngbConfig.animation = false;
  }

  ngOnInit() {}
}
