import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl
} from '@angular/forms';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'jc-request-resume-modal',
  templateUrl: './request-resume-modal.component.html',
  styleUrls: ['./request-resume-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestResumeModalComponent implements OnInit {
  public requestResumeForm: FormGroup;

  public message: string;
  public errorMessage: string;
  public sending: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private apiService: ApiService
  ) {
    this.requestResumeForm = this.formBuilder.group({
      'your-first-name': [null, [Validators.required, Validators.min(2)]],
      'your-last-name': [null, [Validators.required, Validators.min(2)]],
      'your-email': [
        null,
        [Validators.required, Validators.email, Validators.min(5)]
      ],
      'your-company': [null, [Validators.required, Validators.min(2)]],
      'your-employment-type': [null, [Validators.required]],
      'your-employment-position': [null, [Validators.required]],
      'your-subject': [null, [Validators.required, Validators.min(5)]],
      'your-message': [null, [Validators.required, Validators.min(10)]]
    });
  }

  public get yourFirstName(): FormControl {
    return this.requestResumeForm.get('your-first-name') as FormControl;
  }

  public get yourLastName(): FormControl {
    return this.requestResumeForm.get('your-last-name') as FormControl;
  }

  public get yourEmail(): FormControl {
    return this.requestResumeForm.get('your-email') as FormControl;
  }

  public get yourCompany(): FormControl {
    return this.requestResumeForm.get('your-company') as FormControl;
  }

  public get yourEmploymentType(): FormControl {
    return this.requestResumeForm.get('your-employment-type') as FormControl;
  }

  public get yourEmploymentPosition(): FormControl {
    return this.requestResumeForm.get(
      'your-employment-position'
    ) as FormControl;
  }

  public get yourSubject(): FormControl {
    return this.requestResumeForm.get('your-subject') as FormControl;
  }

  public get yourMessage(): FormControl {
    return this.requestResumeForm.get('your-message') as FormControl;
  }

  ngOnInit(): void {}

  public async onSubmit() {
    this.sending = true;

    try {
      const success = await this.apiService
        .postContact('1448/feedback', this.requestResumeForm.getRawValue())
        .toPromise();

      if (success.status === 'mail_sent') {
        this.message = success.message;
      } else {
        this.errorMessage = success.message;
      }
    } catch (error) {}

    this.sending = false;
    this.changeDetectorRef.detectChanges();
  }

  public onCloseModal() {
    this.activeModal.close();
  }

  public download() {
    window.open('https://jonathanconcepcion.com/api/download/1457/');
  }
}
