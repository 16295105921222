import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'jc-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class:
      'page-loader d-flex justify-content-center align-items-center position-fixed flex-column'
  }
})
export class PageLoaderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
