<div class="modal-header">
  <h4 class="modal-title" ngbAutofocus>Request Form</h4>
  <button
    type="button"
    class="close btn btn-link position-relative"
    aria-label="Close"
    (click)="onCloseModal()"
    style="top: 1px;"
  >
    <i-feather name="x"></i-feather>
  </button>
</div>
<div class="modal-body">
  <div class="py-2 px-3">
    <ng-container *ngIf="!message">
      <p class="sub-lead">
        Requesting my resume is my personal information, I would like to know
        what is your purpose. Also, to learn a little bit about you as well.
      </p>
      <p class="mb-0">
        The fields in the form are all required
        <span class="text-danger">*</span>.
      </p>
      <p>
        With minimum at least 2 characters.
      </p>
      <form [formGroup]="requestResumeForm" novalidate>
        <div class="container m-0 p-0">
          <div class="row">
            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label for="your-first-name">
                  First Name <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="your-first-name"
                  formControlName="your-first-name"
                  placeholder="John"
                  [class.is-invalid]="
                    yourFirstName.invalid && yourFirstName.touched
                  "
                  [class.is-valid]="yourFirstName.valid"
                  required
                />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label for="your-last-name">
                  Last Name <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="your-last-name"
                  formControlName="your-last-name"
                  placeholder="Doe"
                  [class.is-invalid]="
                    yourLastName.invalid && yourLastName.touched
                  "
                  [class.is-valid]="yourLastName.valid"
                  required
                />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label for="your-request-resume-email">
                  Email address <span class="text-danger">*</span>
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="your-request-resume-email"
                  formControlName="your-email"
                  placeholder="example@email.com"
                  [class.is-invalid]="yourEmail.invalid && yourEmail.touched"
                  [class.is-valid]="yourEmail.valid"
                  required
                />
              </div>
            </div>
            <div class="col-lg-6 col-sm-12">
              <div class="form-group">
                <label for="your-company">
                  Company Name <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="your-company"
                  formControlName="your-company"
                  placeholder="XYZ Company"
                  [class.is-invalid]="
                    yourCompany.invalid && yourCompany.touched
                  "
                  [class.is-valid]="yourCompany.valid"
                  required
                />
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <legend class="col-form-label">
                  Employment Type <span class="text-danger">*</span>
                </legend>
                <div
                  class="btn-group-toggle"
                  ngbRadioGroup
                  formControlName="your-employment-type"
                >
                  <label
                    ngbButtonLabel
                    class="btn-outline-secondary mr-1 mb-1"
                    [ngClass]="{
                      'btn-secondary': yourEmploymentType.value === 'full-time'
                    }"
                  >
                    <input ngbButton type="radio" value="full-time" /> Full-time
                  </label>
                  <label
                    ngbButtonLabel
                    class="btn-outline-secondary mr-1 mb-1"
                    [ngClass]="{
                      'btn-secondary': yourEmploymentType.value === 'contract'
                    }"
                  >
                    <input ngbButton type="radio" value="contract" /> Contract
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <legend class="col-form-label">
                  Employment Position <span class="text-danger">*</span>
                </legend>
                <div
                  class="btn-group-toggle"
                  ngbRadioGroup
                  formControlName="your-employment-position"
                >
                  <label
                    ngbButtonLabel
                    class="btn-outline-secondary mr-1 mb-1"
                    [ngClass]="{
                      'btn-secondary': yourEmploymentPosition.value === 'lead'
                    }"
                  >
                    <input ngbButton type="radio" value="lead" /> Lead UI
                    Developer
                  </label>
                  <label
                    ngbButtonLabel
                    class="btn-outline-secondary mr-1 mb-1"
                    [ngClass]="{
                      'btn-secondary':
                        yourEmploymentPosition.value === 'manager'
                    }"
                  >
                    <input ngbButton type="radio" value="manager" /> Engineering
                    Manager
                  </label>
                  <label
                    ngbButtonLabel
                    class="btn-outline-secondary mr-1 mb-1"
                    [ngClass]="{
                      'btn-secondary': yourEmploymentPosition.value === 'other'
                    }"
                  >
                    <input ngbButton type="radio" value="other" /> Other
                  </label>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label for="your-request-resume-subject">
                  Subject <span class="text-danger">*</span>
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="your-request-resume-subject"
                  formControlName="your-subject"
                  placeholder="Great thinking"
                  [class.is-invalid]="
                    yourSubject.invalid && yourSubject.touched
                  "
                  [class.is-valid]="yourSubject.valid"
                  required
                />
              </div>
              <div class="form-group mb-3">
                <label for="your-request-resume-message">
                  Message <span class="text-danger">*</span>
                </label>
                <textarea
                  rows="5"
                  class="form-control"
                  id="your-request-resume-message"
                  formControlName="your-message"
                  placeholder="Your message..."
                  [class.is-invalid]="
                    yourMessage.invalid && yourMessage.touched
                  "
                  [class.is-valid]="yourMessage.valid"
                  required
                ></textarea>
              </div>

              <div
                *ngIf="errorMessage"
                class="alert alert-danger mb-3"
                role="alert"
                [innerHtml]="errorMessage"
              ></div>
            </div>
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="message">
      <div class="alert alert-secondary shadow-sm mt-3 mb-3" role="alert">
        <h4 class="alert-heading">Thank you!</h4>
        Thank you for requesting my resume. I am looking forward to speaking
        with you.
      </div>

      <button type="button" class="btn btn-secondary" (click)="download()">
        Download Resume
        <i-feather
          name="download"
          class="small-icon position-relative"
          style="top: -1px;"
        ></i-feather>
      </button>
    </ng-container>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-link" (click)="onCloseModal()">
    {{ !message ? 'Cancel' : 'Close' }}
  </button>

  <button class="btn btn-secondary" type="button" disabled *ngIf="sending">
    Requesting
    <span
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
  </button>

  <button
    type="button"
    class="btn btn-{{
      message || requestResumeForm.invalid ? 'light' : 'secondary'
    }}"
    (click)="onSubmit()"
    [disabled]="message || requestResumeForm.invalid"
    *ngIf="!sending && !message"
  >
    Request Resume
    <i-feather
      name="file-text"
      class="small-icon position-relative"
      style="top: -1px;"
    ></i-feather>
  </button>
</div>
