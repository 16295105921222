import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { HttpUtilsService } from '../utilities/utilities.service';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private httpClient: HttpClient,
    private httpUtilsService: HttpUtilsService
  ) {}

  public getPortfolio(pathName: string): Observable<any> {
    return this.httpClient
      .get(environment.api + pathName)
      .pipe(
        map(this.mapApiResponse),
        catchError(this.httpUtilsService.handleError)
      );
  }

  public postContact(pathName: string, formValues: any): Observable<any> {
    const FORM_DATA = new FormData();

    Object.keys(formValues).forEach(key => {
      FORM_DATA.append(key, formValues[key]);
    });

    return this.httpClient
      .post(environment.contact + pathName, FORM_DATA)
      .pipe(
        map(this.mapApiResponse),
        catchError(this.httpUtilsService.handleError)
      );
  }

  private mapApiResponse(response: any): any {
    return response;
  }
}
