import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import {
  NgbPopoverModule,
  NgbTooltipModule,
  NgbButtonsModule
} from '@ng-bootstrap/ng-bootstrap';

import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';

import { NavigationComponent } from './components/navigation/navigation.component';
import { MehComponent } from './components/meh/meh.component';
import { FooterComponent } from './components/footer/footer.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PageContentComponent } from './components/page-content/page-content.component';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { SendAnEmailButtonComponent } from './components/send-an-email-button/send-an-email-button.component';
import { RequestResumeButtonComponent } from './components/request-resume-button/request-resume-button.component';

import { GotoPageDirective } from './directives/goto-page/goto-page.directive';
import { RequestResumeModalComponent } from './components/request-resume-modal/request-resume-modal.component';
import { PageLoaderComponent } from './components/page-loader/page-loader.component';

@NgModule({
  imports: [
    CommonModule,
    FeatherModule.pick(allIcons),
    NgbPopoverModule,
    NgbTooltipModule,
    NgbButtonsModule,
    RouterModule,
    ReactiveFormsModule
  ],
  exports: [
    FeatherModule,
    NavigationComponent,
    MehComponent,
    FooterComponent,
    PageHeaderComponent,
    PageContentComponent,
    PageContainerComponent,
    GotoPageDirective,
    SendAnEmailButtonComponent,
    RequestResumeButtonComponent,
    RequestResumeModalComponent,
    PageLoaderComponent
  ],
  declarations: [
    NavigationComponent,
    MehComponent,
    FooterComponent,
    PageHeaderComponent,
    PageContentComponent,
    PageContainerComponent,
    GotoPageDirective,
    SendAnEmailButtonComponent,
    RequestResumeButtonComponent,
    RequestResumeModalComponent,
    PageLoaderComponent
  ]
})
export class SharedModule {}
