import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'jc-meh',
  templateUrl: './meh.component.html',
  styleUrls: ['./meh.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'position-fixed faster'
  }
})
export class MehComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
