import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

@Component({
  selector: 'jc-page-container',
  templateUrl: './page-container.component.html',
  styleUrls: ['./page-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'd-block'
  }
})
export class PageContainerComponent implements OnInit {
  @Input() public showFooter: boolean;

  constructor() {
    this.showFooter = true;
  }

  ngOnInit(): void {}
}
