import { Directive, HostListener, Input } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
  selector: '[jcGotoPage]'
})
export class GotoPageDirective {
  @Input() route: string;

  constructor(private router: Router) {}

  @HostListener('click', ['$event']) onClick($event) {
    $event.preventDefault();
    this.router.navigateByUrl(this.route);
  }
}
