import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostListener,
  Input
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestResumeModalComponent } from '../request-resume-modal/request-resume-modal.component';

@Component({
  selector: 'jc-request-resume-button',
  templateUrl: './request-resume-button.component.html',
  styleUrls: ['./request-resume-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'd-inline-block btn btn-secondary'
  }
})
export class RequestResumeButtonComponent implements OnInit {
  @HostListener('click') public async onClick() {
    this.openRequestFormModal();
  }

  @Input() showIcon: boolean;
  @Input() isButton: boolean;

  constructor(private ngbModal: NgbModal) {
    this.showIcon = true;
    this.isButton = true;
  }

  ngOnInit(): void {}

  private openRequestFormModal() {
    this.ngbModal.open(RequestResumeModalComponent, {
      centered: true,
      size: 'lg',
      keyboard: false,
      backdrop: 'static',
      windowClass: 'animated fadeIn faster',
      scrollable: true
    });
  }
}
