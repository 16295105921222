import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';

const DEFAULT_CLASSES = 'lead text-serif mb-3 animated slideInUp d-block';

@Component({
  selector: 'jc-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { '[class]': 'className' }
})
export class PageHeaderComponent implements OnInit {
  @Input() public borderBottom: boolean;
  public className: string;

  constructor() {
    this.borderBottom = true;
  }

  ngOnInit(): void {
    this.className =
      DEFAULT_CLASSES + (this.borderBottom ? ' is-border-bottom' : '');
  }
}
