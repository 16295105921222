<div id="footer-container" class="w-100">
  <p class="mb-1"><img src="assets/images/jonathanconcepcion-light.png" /></p>
  <p class="mb-0">
    Lead UI and Engineering Manager at <a href="#">TrustArc</a>
  </p>
  <p class="mb-0">
    The Creator of
    <a target="_blank" rel="nofollow" href="https://snacapp.com">CreateCards</a>
    an interactive flashcards.
  </p>
  <p class="mb-5">
    The Creator of
    <a target="_blank" rel="nofollow" href="https://recurcoin.com">RecurCoin</a>
    a crypto currency tool.
  </p>
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-lg-6 col-md-6">
        <p class="m-0">&copy; {{ year }} Jonathan Concepcion</p>
      </div>
      <div class="col-lg-6 col-md-6 text-lg-right text-md-right">
        <p class="m-0">
          <i-feather
            ngbTooltip="LinkedIn"
            container="body"
            tooltipClass="animated fadeIn faster"
            role="button"
            tabindex="0"
            (click)="gotoSocial('linkedin')"
            name="linkedin"
            class="mr-2 icon-button linkedin-icon"
          ></i-feather>
          <i-feather
            ngbTooltip="Codepen"
            container="body"
            tooltipClass="animated fadeIn faster"
            role="button"
            tabindex="0"
            (click)="gotoSocial('codepen')"
            name="codepen"
            class="mr-2 icon-button"
          ></i-feather>
          <i-feather
            ngbTooltip="Facebook"
            container="body"
            tooltipClass="animated fadeIn faster"
            role="button"
            tabindex="0"
            (click)="gotoSocial('facebook')"
            name="facebook"
            class="mr-2 icon-button facebook-icon"
          ></i-feather>
          <i-feather
            ngbTooltip="Instagram"
            container="body"
            tooltipClass="animated fadeIn faster"
            role="button"
            tabindex="0"
            (click)="gotoSocial('instagram')"
            name="instagram"
            class="mr-2 icon-button instagram-icon"
          ></i-feather>
          <i-feather
            ngbTooltip="Github"
            container="body"
            tooltipClass="animated fadeIn faster"
            role="button"
            tabindex="0"
            (click)="gotoSocial('github')"
            name="github"
            class="mr-2 icon-button github-icon"
          ></i-feather>
        </p>
      </div>
    </div>
  </div>
</div>
