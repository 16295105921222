import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  HostListener
} from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'jc-send-an-email-button',
  templateUrl: './send-an-email-button.component.html',
  styleUrls: ['./send-an-email-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'd-inline-block btn btn-secondary'
  }
})
export class SendAnEmailButtonComponent implements OnInit {
  @HostListener('click') public async onClick() {
    await this.router.navigateByUrl('contact');
  }

  constructor(private router: Router) {}

  ngOnInit(): void {}
}
