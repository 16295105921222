import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'jc-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'min-vh-100 flex-shrink-1'
  }
})
export class NavigationComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
