import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'jc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'd-flex align-items-center section-container'
  }
})
export class FooterComponent {
  public year: number = new Date().getFullYear();

  public gotoSocial(social: string) {
    switch (social) {
      case 'linkedin':
        window.open('https://www.linkedin.com/in/hijoncon');

      case 'codepen':
        window.open('https://codepen.io/hijoncon');

      case 'facebook':
        window.open('https://www.facebook.com/hijoncon');

      case 'twitter':
        window.open('https://www.facebook.com/hijoncon');

      case 'github':
        window.open('https://github.com/hijoncon');
    }
  }
}
