<div
  [ngbPopover]="mehPopover"
  placement="top-right"
  popoverClass="animated fadeIn faster popover-meh text-center"
  autoClose="true"
  container="body"
  #popOver
>
  <img src="/assets/favicon/apple-icon.png" />
</div>

<ng-template #mehPopover>
  I'm glad you drop by on my website. Maybe we can work together? Feel free to
  <a routerLink="contact">email</a> me or download my
  <a routerLink="resume">resume</a>.
</ng-template>
