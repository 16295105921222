import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'jc-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'animated fadeIn delay-1s d-block'
  }
})
export class PageContentComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
